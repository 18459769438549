<template>
  <el-drawer :title="title" :visible.sync="drawer" :direction="direction" :before-close="handleClose" destroy-on-close
    custom-class="drawerClass" size="70%">
    <div style="padding: 20px">
      <el-table :data="tableData" style="width: 100%" :max-height="height" v-loading="loading">
        <el-table-column prop="Id" label="单号">
        </el-table-column>
        <el-table-column prop="CreateTime" label="日期" width="200">
        </el-table-column>
        <el-table-column prop="PayType" label="收款方式">
          <template slot-scope="scope">
            <el-tag effect="dark" v-if="scope.row.PayType==3">现金</el-tag>
            <el-tag effect="dark" type="success"  v-if="scope.row.PayType==2">支付宝</el-tag>
            <el-tag effect="dark" type="warning"  v-if="scope.row.PayType==1">微信</el-tag>
            <el-tag  effect="dark" type="danger"  v-if="scope.row.PayType==0">转账</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="Money" label="收款金额">
          <template slot-scope="scope">
            {{ toThousands(scope.row.Money) }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination layout="total,prev, pager, next,sizes" :total="pagination.total" :current-page="pagination.current"
        :page-size="pagination.pageSize" :page-sizes="pagination.pageSizeOptions"
        style="text-align: right; margin-top: 8px; padding: 0" @current-change="currentChange"
        @size-change="handleSizeChange">
      </el-pagination>
    </div>

    <div style="height: 50px"></div>
    <div :style="{
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '5px 16px',
      background: '#fff',
      textAlign: 'right',
      zIndex: 99999,
    }">
      <el-button @click="drawer = false">取消</el-button>
    </div>
   

   

  </el-drawer>
</template>

<script>
import { getMonthRange, toThousands} from '../../utils/Tools';

// 收款弹窗
export default {
  components: {
  },
  props: {
    parentObj: { type: Object },
  },
  data() {
    return {
      getMonthRange,
      toThousands,
      // 日期
      range: [],
      drawer: false,
      direction: "rtl",
      btnLoading: false,
      title: "",
      loading: false,
      timeout: null,
      tableData: [],
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      sorter: {
        field: "CreateTime",
        order: "desc",
      },
      queryParam: {
        // 客户id
        CustomerId: "",
        Type:0
      },
      sortList: [1, 2, 3, 4, 5, 6],
      selectRows: [],
      activeName: "2",
      tagList: [],
      dialogVisible: false,
      typeObj: {},
      dialogLoading: false,
      // 传递过来的客户id
      CustomerId:"",
      // 传递过来的公司id
      CompanyId:""

    };
  },
  computed: {
    height() {
      return document.documentElement.clientHeight * 0.75;
    },
  },
  methods: {
    // 对账单添加
    arrearListAdd() {
      let obj={
        CustomerId:this.CustomerId,
        CompanyId:this.CompanyId,
        range:this.range
      }
      console.log('对账单添加了',obj)
      this.$refs.ReconArrearDetail.openForm( '添加', obj)
    },
    // 开始日期结束日期选择事件
    changeTime(e) {
      console.log('时间选择', e)
      this.queryParam.StartTime= e[0]
      this.queryParam.EndTime= e[1]
      this.tableData=[]
      this.loading = true
      this.getBillConfirmList()
    },
    dialogConfirm() {
      if (!this.typeObj.Name) return this.$message.error("请输入商品分类名称");
      if (!this.typeObj.CompanyId)
        this.typeObj.CompanyId = this.queryParam.CommpayId;
      this.dialogLoading = true;
      this.$http.post("/BO/InventType/SaveData", this.typeObj).then((res) => {
        this.dialogLoading = false;
        if (res.Success) {
          this.dialogVisible = false;
          this.$message.success("操作成功");
          this.getDataList();
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    // 列表项查看
    handleView(row) {
      console.log("查看了",row)
      let obj={
        BillConfirmId:row.Id,
        CustomerId:this.CustomerId,
        CompanyId:row.CompanyId,
        range:this.range
      }
      this.$refs.ReconArrearDetail.openForm( '查看', obj)
      // this.typeObj = row;
      // this.dialogVisible = true;
    },
    // 分类新增
    handleAdd() { },
    currentChange(page) {
      this.pagination.current = page;
      this.tableData=[]
      this.getBillConfirmList();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.tableData=[]
      this.getBillConfirmList();
    },
    saveCustomerGoods() {
      this.$http
        .post("/BO/InventCustomers/AddDataList?Code=" + this.entity.cInvCode, {
          Data: this.tableData,
        })
        .then((res) => {
          if (res.Success) {
            this.$message.success("操作成功");
            this.parentObj.getDataList();
            this.drawer = false;
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    handleSelectionChange(val) {
      this.selectRows = val;
    },

    handleDelete(scope) {
      this.tableData.splice(scope.$index, 1);
    },
    // 公司选择
    CompanySelect(row) {
      this.entity.CompanyName = row.Title;
      this.entity.CompanyId = row.Id;
    },
    init() {
      this.drawer = true;
    },
    // 获取收款记录列表数据收款记录列表数据
    getBillConfirmList() {
      this.loading=true
      this.$http.post('/BO/BIllcollection/GetDataList',  {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || "Id",
          SortType: this.sorter.order,
          Search: this.queryParam,
        }).then(resJson => {
        if (resJson.Success) {
          this.loading=false
          console.log('获取收款记录列表收款记录列表收款记录列表收款记录列表数据',resJson.Data)
          this.tableData = resJson.Data;
          this.tableData.forEach(item=>{
              item.CreateTime = item.CreateTime.substring(0,10)
            })
            if (this.tableData) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
        }else{
          this.loading=false
        }
      })
    },
    openForm(Id, CommpayId) {
      this.CustomerId = Id
      this.CompanyId = CommpayId
      console.log("传递过来的收款记录idididididididi", this.CustomerId, this.CompanyId);

      this.init();
      this.title = "收款记录";
      // this.range = getMonthRange(1)
      // this.queryParam.StartTime = this.range[0]
      // this.queryParam.EndTime = this.range[1]
      if (Id) {
        this.queryParam.CustomerId = Id;
        this.getBillConfirmList();
      }
      console.log('获取月份了', this.range)
    },
    // 获取商品分类列表数据
    getDataList() {
      this.loading = true;
      this.$http
        .post("/BO/InventType/GetDataList", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || "Id",
          SortType: this.sorter.order,
          Search: this.queryParam,
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success) {
            this.tableData = resJson.Data;
            if (this.tableData) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
          }
        });
    },

    handleClose(done) {
      done();
    },
    handleSelect(item) {
      console.log(item);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-drawer__header {
  margin-bottom: 10px;
}
.qianImg{
  width: 70px;
  height: 160px;	
  transform: rotate(-90deg);
}

.drawerClass {
  padding: 10px;

  .posBox {
    font-weight: 600;
    width: calc(100% - 42px);
    margin-left: 42px;
    line-height: 2;
    color: #333;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
